/* @import url(https://fonts.googleapis.com/css?family=Poppins:100,
 100italic,
200,
200italic,
300,
300italic,
regular,
italic,
500,
500italic,
600,
600italic,
700,
700italic,
800,
800italic,
900,
900italic); */
.calendar-container * {
  font-family: "Poppins", sans-serif !important;
}

.calendar-container .react-calendar__viewContainer {
  /* background-color: yellow !important; */
  margin-top: 3rem;
}

.calendar-container .react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0 1rem;
}

.calendar-container .react-calendar__navigation__prev-button,
.calendar-container .react-calendar__navigation__next-button {
  color: #7f8596;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding: 0 1rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
}

.calendar-container .react-calendar__navigation__label {
  background-color: #fff;
}

.calendar-container .react-calendar__navigation__prev2-button,
.calendar-container .react-calendar__navigation__next2-button {
  display: none;
}

.calendar-container .react-calendar button {
  border: 0;
  outline: none;
}

.calendar-container .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.calendar-container .react-calendar button:enabled:hover {
  cursor: pointer;
}

.calendar-container .react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.calendar-container .react-calendar__month-view__weekdays__weekday {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3rem !important;
  color: #22252d !important;
}

.calendar-container .react-calendar__month-view__days {
  margin-top: 2rem;
}

.calendar-container .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.calendar-container .react-calendar__tile {
  background: none;
  padding: 0.5rem 0;
  font-size: 1.3rem;
}

.calendar-container .react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.calendar-container .react-calendar__tile:enabled:hover,
.calendar-container .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.calendar-container .react-calendar__tile--now {
  color: #e45f35;
  border-radius: 10px;
}

.calendar-container .react-calendar__tile--hasActive {
  background: #76baff;
}

.calendar-container .react-calendar__tile--hasActive:enabled:hover,
.calendar-container .react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.calendar-container .react-calendar__tile--active {
  color: #e45f35;
}

.calendar-container .react-calendar__tile--active:enabled:hover,
.calendar-container .react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.calendar-container .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.calendar-container .react-calendar__navigation__label__labelText {
  font-size: 1.6rem;
}

@media (max-width: 1120px) {
  .calendar-container .react-calendar__tile {
    padding: 0.8rem 0;
    font-size: 1.4rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1.2rem !important;
  }
}

@media (max-width: 1050px) {
  .calendar-container .react-calendar__tile {
    padding: 0.9rem 0;
    font-size: 1.2rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 880px) {
  .calendar-container .react-calendar__tile {
    padding: 0.4rem 0;
    font-size: 0.9rem;
  }
  .calendar-container .react-calendar__viewContainer {
    margin-top: 0;
  }
  .calendar-container .react-calendar__month-view__days {
    margin-top: 0;
  }
  .calendar-container .react-calendar__navigation__label__labelText {
    font-size: 1.2rem;
  }
  .calendar-container .react-calendar__navigation__prev-button,
  .calendar-container .react-calendar__navigation__next-button {
    padding: 0 0.5rem;
    line-height: 1;
  }
}

@media (max-height: 1150px) {
  .calendar-container .react-calendar__viewContainer {
    margin-top: 0;
  }
  .calendar-container .react-calendar__month-view__days {
    margin-top: 0;
  }
  .calendar-container .react-calendar__tile {
    padding: 0.4rem 0;
    font-size: 1.3rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1.2rem !important;
  }
}

@media (max-height: 810px) {
  .calendar-container .react-calendar__tile {
    padding: 0.3rem 0;
    font-size: 1rem;
  }
  .calendar-container .react-calendar__navigation__label__labelText {
    font-size: 1rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 1600px) and (max-height: 900px) {
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1rem !important;
  }
}

@media (max-height: 780px) {
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1rem !important;
  }
}

@media (max-height: 680px) {
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 0.8rem !important;
  }
  .calendar-container .react-calendar__tile {
    font-size: 0.8rem;
  }
  .calendar-container .react-calendar {
    padding: 1rem 1rem 0;
  }
}

@media (max-height: 600px) {
  .calendar-container .react-calendar__navigation {
    padding: 0 0 0.5rem;
  }
  .calendar-container .react-calendar__navigation__prev-button,
  .calendar-container .react-calendar__navigation__next-button {
    padding: 0 0.7rem;
    line-height: 1;
  }
}

@media (max-height: 900px) {
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 0.9rem !important;
  }
  .calendar-container .react-calendar__tile {
    font-size: 0.9rem;
  }
}

@media (max-width: 710px) {
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 0.4rem !important;
  }
  .calendar-container .react-calendar__tile {
    font-size: 0.7rem;
  }
}

/* ================================================== */

/* .calendar-container .react-calendar * {
  font-family: 'Poppins', sans-serif !important;
} */

.calendar-container .react-calendar__navigation {
  width: 80%;
  margin: 0 auto;
}

.calendar-container .react-calendar__month-view__weekdays__weekday {
  color: #9a9fa5 !important;
  font-size: 1.6rem !important;
}

.calendar-container .react-calendar__navigation__prev-button,
.calendar-container .react-calendar__navigation__next-button {
  font-size: 2rem !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.calendar-container .react-calendar__tile {
  font-size: 1.5rem;
  padding: 1.5rem 0;
}

@media (max-height: 1260px) {
  .calendar-container .react-calendar__tile {
    padding: 1.5rem 0;
  }
  .calendar-container .react-calendar__viewContainer {
    margin-top: 2rem;
  }
}

@media (max-height: 1180px) {
  .calendar-container .react-calendar {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (max-height: 1050px) {
  .calendar-container .react-calendar__viewContainer {
    margin-top: 1rem;
  }
}

@media (max-height: 940px) {
  .calendar-container .react-calendar__tile {
    padding: 1rem 0;
  }
}

@media (max-height: 845px) {
  .calendar-container .cln {
    font-size: 1.5rem;
  }
  .calendar-container .react-calendar__viewContainer {
    margin-top: 0.5rem;
  }
  .calendar-container .react-calendar__tile {
    padding: 0.7rem 0;
  }
}

/* ===================== */

/* ===== MAX WIDTH ===== */

/* ===================== */

@media (max-width: 1610px) {
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1.1rem !important;
  }
  .calendar-container .react-calendar__tile {
    font-size: 0.9rem;
  }
  .calendar-container .react-calendar__navigation {
    margin: 0 auto;
  }
  .calendar-container .react-calendar__navigation {
    width: 80%;
  }
}

@media (max-width: 1470px) {
  .calendar-container .react-calendar__navigation {
    width: 90%;
  }
}

@media (max-width: 1205px) {
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1.2rem !important;
  }
  .calendar-container .react-calendar__tile {
    font-size: 1rem;
  }
}

@media (max-width: 1050px) {
  .calendar-container .cln {
    font-size: 1.3rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1rem !important;
  }
  .calendar-container .react-calendar__navigation__prev-button,
  .calendar-container .react-calendar__navigation__next-button {
    font-size: 1.6rem !important;
    border-radius: 0.5rem;
    width: auto;
    height: auto;
  }
}

/* ===================== */

/* ===== MAX WIDTH ===== */

/* ===================== */

/* ==================================== */

/* ===== MAX WIDTH AND MAX HEIGHT ===== */

/* ==================================== */

@media (min-width: 1800px) and (max-height: 750px) {
  .calendar-container .react-calendar__tile {
    font-size: 0.8rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1rem !important;
  }
  .calendar-container .cln {
    font-size: 1.2rem;
  }
}

@media (max-width: 1550px) and (max-height: 740px) {
  .calendar-container .react-calendar__tile {
    font-size: 0.8rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 1rem !important;
  }
  .calendar-container .cln {
    font-size: 1.2rem;
  }
}

@media (max-width: 1300px) and (max-height: 650px) {
  .calendar-container .cln {
    font-size: 1rem;
  }
  .calendar-container .react-calendar__month-view__weekdays__weekday {
    font-size: 0.9rem !important;
  }
  .calendar-container .react-calendar__viewContainer {
    margin-top: 0rem;
  }
  .calendar-container .react-calendar__tile {
    padding: 0.4rem 0;
  }
}

/* ==================================== */

/* ===== MAX WIDTH AND MAX HEIGHT ===== */

/* ==================================== */
