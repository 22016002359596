.notification-container {
    position: absolute;
    top: calc(60px + 1rem);
    right: 15%;
    width: clamp(100px, 95%, 400px);
    background-color: #fff;
    box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.2), 0 0 0.5rem rgba(0, 0, 0, 0.2);
    padding: 0;
    border-radius: 1rem;
    z-index: 500;
    max-height: 0;
    overflow: auto;
    transition: 200ms ease-in-out max-height, 200ms ease-in-out padding;
}

.notification-container-open {
    max-height: 500px;
    padding: 1rem 0.5rem;
}

.notification-container * {
    color: #202126;
}

.notification-title {
    border-bottom: 2px solid #ededed;
    padding: 0 0 1rem 1rem;
    margin-bottom: 1rem;
}

.notification-title h2 {
    font-weight: 600;
    text-align: left !important;
}

.notification-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 0.5rem;
}

.notification-row:hover {
    background-color: #e7e7e2;
    cursor: pointer;
}

.notification-row-left {
    display: flex;
    align-items: flex-start;
    column-gap: 1rem;
}

.notification-image {
    height: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: grid;
    place-content: center;
}

.notification-container .notification-details * {
    text-align: left !important;
}

.notification-container .notification-details p {
    color: #80868d;
}

.notification-time {
    color: #97989d;
    font-weight: 500;
    width: 70px;
    text-align: right;
}

@media (max-width: 599px) {
    .notification-container {
        right: 5%;
        width: 90%;
        max-width: 400px;
    }
}

@media (max-width: 450px) {
    .notification-container {
        right: 5%;
        left: 5%;
        width: 90%;
        max-width: 400px;
    }
}

.notification-container .achievement .notification-image {
    background-color: #cabdff;
}

.notification-container .workout .notification-image {
    background-color: #b1e5fc;
}

.notification-container .new_chat_notify .notification-image {
    background-color: #fbac4f;
}

@media (max-width: 1700px) {
    .notification-title h2 {
        font-size: 1.3rem;
    }
    .notification-image {
        height: 50px;
    }
    .notification-container .notification-details h3 {
        font-size: 1rem;
    }
    .notification-container .notification-details p {
        font-size: 0.9rem;
    }
    .notification-time {
        font-size: 0.9rem;
    }
}

@media (max-width: 1300px) {
    .notification-title h2 {
        font-size: 1.2rem;
    }
    .notification-image {
        height: 45px;
    }
    .notification-image img {
        width: 20px;
    }
    .notification-container .notification-details h3 {
        font-size: 0.9rem;
    }
    .notification-container .notification-details p {
        font-size: 0.8rem;
    }
    .notification-time {
        font-size: 0.8rem;
    }
}

@media (max-width: 599px) {
    .notification-title h2 {
        font-size: 1.1rem;
    }
    .notification-image {
        height: 40px;
    }
    .notification-image img {
        width: 18px;
    }
}