.progress-grid-container {
    display: grid;
    grid-template-columns: minmax(200px, 700px) minmax(200px, 1000px);
    grid-template-rows: minmax(100px, 500px) minmax(100px, 250px) minmax( 100px, 300px);
    grid-template-areas: 'progressGraphFromPC progressGraphFromPC' 'rank progressCalendar' 'health progressCalendar';
    place-content: center;
    row-gap: 5rem;
    column-gap: 15rem;
    margin: 0 auto 0 60px;
    padding: 3rem;
    height: calc(100vh - 65px);
    max-height: 100%;
}

.progress-rank {
    grid-area: rank;
}

.progress-health {
    grid-area: health;
}

.progress-calendar {
    grid-area: progressCalendar;
}

.progressGraphFromPC {
    grid-area: progressGraphFromPC;
}

.progress-article {
    background-color: #fff;
    border-radius: 3rem;
    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.3);
    padding: 2rem;
}

/* ====================================== */

/* ============= / GRAPGH / ============= */

/* ====================================== */

.progress-article-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.progress-article-navigation .left {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.progress-article-navigation .right * {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.progress-article-navigation .left h1 {
    font-size: 1.6rem;
    font-weight: 600;
}

.progress-orange-rect {
    width: 20px;
    height: 35px;
    background-color: #ff592c;
    border-radius: 0.5rem;
}

.progress-select {
    height: 40px;
    border-radius: 0.5rem !important;
}

.progress-component-content {
    display: flex;
    column-gap: 2rem;
    height: 90%;
}

.graph-container {
    width: 80%;
}

.progress-component-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 20%;
    min-width: 230px;
}

.progress-component-right-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 1rem;
    padding: 2rem;
    border-radius: 1.3rem;
    background-color: #fcfcfc;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
}

.progress-component-right-inner>img {
    width: 50px;
}

/* ====================================== */

/* ============= / GRAPGH / ============= */

/* ====================================== */

/* ==================================== */

/* ============= / RANK / ============= */

/* ==================================== */

.progress-grid-container .my-rank-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    padding-left: 3rem;
    padding-right: 3rem;
}

.progress-grid-container .my-rank-content .right {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 80%;
    background-color: #efefef;
    border-radius: 2rem;
}

.progress-grid-container .my-rank-content .left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-grid-container .my-rank-content .left img {
    width: 90px;
}

.progress-grid-container .my-rank-content .left p {
    margin-top: 0.5rem;
}

/* ==================================== */

/* ============= / RANK / ============= */

/* ==================================== */

/* ======================================== */

/* ============= / CALENDAR / ============= */

/* ======================================== */

.progress-calendar .react-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.progress-calendar .react-calendar__viewContainer {
    height: 100%;
    margin-top: 3rem;
}

.progress-calendar .react-calendar__viewContainer .react-calendar__month-view {
    height: 100%;
}

.progress-calendar .react-calendar__month-view>div {
    height: 100%;
}

.progress-calendar .react-calendar__month-view>div>div {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    height: 100%;
}

.progress-calendar .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.progress-calendar .react-calendar__navigation__prev2-button,
.progress-calendar .react-calendar__navigation__next2-button {
    display: none;
}

.progress-calendar .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3rem;
}

.progress-calendar .react-calendar__navigation button {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 500;
    background-color: transparent;
    border: none;
}

.progress-calendar .react-calendar__navigation button:nth-of-type(2),
.progress-calendar .react-calendar__navigation button:nth-of-type(4) {
    outline: 2px solid #efefef;
    background-color: transparent;
    border: none;
    width: 30px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    color: #7f8596;
}

.progress-calendar .react-calendar__month-view__weekdays * {
    text-align: center;
    font-size: 1.1rem;
}

.progress-calendar .react-calendar__month-view__weekdays .react-calendar__month-view__weekdays__weekday abbr {
    font-weight: 600;
    color: #7f8596;
    text-transform: uppercase;
}

.progress-calendar .react-calendar__tile {
    background-color: transparent;
    border: none;
    padding: 1.5rem 1rem;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 500;
}

.progress-calendar .react-top-right button:enabled:hover {
    cursor: pointer;
}

.progress-calendar .react-calendar__month-view__days__day--neighboringMonth abbr {
    color: rgba(127, 133, 150, 0.3);
}

.progress-calendar .react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.progress-calendar .react-calendar__tile:enabled:hover,
.progress-calendar .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.progress-calendar .react-calendar__tile--now {
    outline: 2px solid #e6e6e6;
    border-radius: 0.3rem;
}

.progress-calendar .react-calendar__tile--now abbr {
    color: #e45f35 !important;
}

.progress-calendar .react-calendar__tile--hasActive {
    background: rgba(118, 186, 255, 0.8);
}

.progress-calendar .react-calendar__tile--hasActive:enabled:hover,
.progress-calendar .react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.progress-calendar .react-calendar__tile--active {
    color: #e45f35;
}

.progress-calendar .react-calendar__tile--active:enabled:hover,
.progress-calendar .react-calendar__tile--active:enabled:focus {
    background: rgba(118, 186, 255, 0.8);
}

.progress-calendar .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

/* ======================================== */

/* ============= / CALENDAR / ============= */

/* ======================================== */

/* ====================================== */

/* ============= / HEALTH / ============= */

/* ====================================== */

.progress-health .health-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    height: 70%;
    gap: 2rem;
    text-align: center;
}

.single-health-box {
    outline: 2px solid #e7e7e1;
    border-radius: 1rem;
    padding: 1rem 0;
}

.single-health-box span {
    font-weight: 600;
}

.single-health-box-bottom {
    background-color: #f9f9f9;
    outline: none;
}

.single-health-box-bottom:last-child {
    background-color: #eafae4;
    text-transform: capitalize;
    color: #83bf6e;
}

/* ====================================== */

/* ============= / HEALTH / ============= */

/* ====================================== */

/* ============================================= */

/* ============= / MEDIA QUERIES / ============= */

/* ============================================= */

@media (max-height: 1280px) {
    .progress-grid-container {
        grid-template-rows: minmax(100px, 500px) minmax(100px, 200px) minmax( 100px, 250px);
        row-gap: 3rem;
    }
    .progress-calendar .react-calendar {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .progress-calendar .react-calendar__tile {
        padding: 0.5rem;
    }
    .progress-grid-container .my-rank-content .left img {
        width: 70px;
    }
    .progress-grid-container .my-rank-content .left p {
        margin-top: 0;
    }
}

@media (max-height: 1130px) {
    .progress-component-right-inner {
        padding: 1rem;
    }
    .progress-component-right-inner>img {
        width: 40px;
    }
}

@media (max-height: 1050px) {
    .progress-component-right {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media (max-height: 1000px) {
    .progress-grid-container {
        column-gap: 8rem;
    }
    .progress-component-right {
        padding-top: 0;
    }
    .progress-component-right-inner>img {
        width: 25px;
    }
    .progress-component-right-inner .right span {
        font-size: 0.8rem;
    }
    .progress-component-right-inner .right h3 {
        font-size: 1rem;
        line-height: 1;
    }
}

@media (max-height: 960px) {
    .progress-grid-container {
        grid-template-rows: minmax(100px, 500px) minmax(100px, 150px) minmax( 100px, 200px);
        row-gap: 1.5rem;
    }
    .progress-article {
        border-radius: 2rem;
        padding-top: 1rem;
    }
    .progress-article-navigation {
        margin-bottom: 1rem;
    }
    .progress-article-navigation .left h1 {
        font-size: 1.1rem;
    }
    .progress-orange-rect {
        width: 15px;
        height: 25px;
        border-radius: 0.5rem;
    }
    .progress-select {
        height: 30px;
        font-size: 0.8rem !important;
    }
    .progress-grid-container .my-rank-content .left p {
        font-size: 0.8rem;
    }
    .progress-health .health-content {
        row-gap: 1rem;
    }
    .single-health-box {
        border-radius: 0.5rem;
        padding: 0.5rem 0;
        font-size: 0.9rem;
    }
    .progress-calendar .react-calendar__tile {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    }
    .progress-calendar .react-calendar__navigation button {
        font-size: 1rem;
    }
    .progress-calendar .react-calendar__month-view__weekdays * {
        font-size: 0.9rem;
    }
    .progress-calendar .react-calendar {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .progress-calendar .react-calendar__viewContainer {
        margin-top: 1.5rem;
    }
}

@media (max-height: 795px) {
    .progress-grid-container {
        grid-template-rows: minmax(100px, 500px) minmax(100px, 150px) minmax( 100px, 150px);
        padding-top: 1rem;
    }
    .progress-article {
        border-radius: 1rem;
    }
    .progress-article-navigation {
        margin-bottom: 0.5rem;
    }
    .progress-article-navigation .left h1 {
        font-size: 1rem;
    }
    .progress-orange-rect {
        width: 10px;
        height: 20px;
        border-radius: 0.2rem;
    }
    .progress-health .health-content {
        row-gap: 0.5rem;
    }
    .single-health-box {
        padding: 0.3rem 0;
        font-size: 0.8rem;
        border-radius: 0.3rem;
    }
    .progress-calendar .react-calendar__tile {
        padding: 0.4rem 1rem;
    }
    .progress-calendar .react-calendar__viewContainer {
        margin-top: 1rem;
    }
}

@media (max-height: 710px) {
    .progress-grid-container {
        grid-template-rows: minmax(100px, 500px) minmax(100px, 130px) minmax( 100px, 130px);
        height: calc(100vh - 70px);
        padding-top: 0.5rem;
    }
    .progress-calendar .react-calendar__tile {
        padding: 0.2rem 1rem;
    }
    .progress-calendar .react-calendar__viewContainer {
        margin-top: 0.5;
    }
    .progress-component-right-inner {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-radius: 1rem;
    }
}

@media (max-width: 1410px) {
    .progress-component-content {
        column-gap: 1rem;
    }
    .graph-container {
        width: 75%;
    }
}

@media (max-width: 1140px) {
    .graph-container {
        width: 70%;
    }
}

@media (max-width: 1000px) {
    .progress-grid-container {
        grid-template-areas: 'progressGraphFromPC progressGraphFromPC' 'rank rank' 'health health' 'progressCalendar progressCalendar';
        grid-template-rows: auto auto auto auto;
        grid-template-columns: 1fr;
        height: auto;
        max-height: auto;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .progress-article-navigation {
        margin-bottom: 1rem;
    }
    .progressGraphFromPC {
        padding-bottom: 0;
        max-width: 100%;
    }
    .progress-component-content {
        flex-direction: column;
    }
    .graph-container {
        width: 100%;
        min-height: 250px;
    }
    .progress-component-right {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 1rem;
    }
    .progress-article-navigation .left {
        display: flex;
        align-items: center;
        column-gap: 1rem;
    }
    .progress-article-navigation .left h1 {
        font-size: 1.3rem;
    }
    .progress-orange-rect {
        width: 13px;
        height: 25px;
        border-radius: 0.3rem;
    }
    .progress-calendar .react-calendar__tile {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    }
    .progress-calendar .react-calendar__navigation button {
        font-size: 1rem;
    }
    .progress-calendar .react-calendar__month-view__weekdays * {
        font-size: 0.9rem;
    }
    .progress-calendar .react-calendar {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .progress-calendar .react-calendar__viewContainer {
        margin-top: 1.5rem;
    }
    .progress-bcg {
        bottom: 50% !important;
        transform: translate(-50%, 70%) !important;
        width: 80% !important;
    }
}

@media (max-width: 599px) {
    .progress-grid-container {
        margin-left: 0;
    }
}

@media (max-width: 545px) {
    .progress-article {
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 1.5rem;
    }
    .progress-component-right-inner {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
        column-gap: 0.5rem;
    }
    .progress-component-right-inner>img {
        width: 25px;
    }
    .progress-component-right-inner .right span {
        font-size: 0.7rem;
    }
    .progress-component-right-inner .right h3 {
        font-size: 0.8rem;
    }
}

@media (max-width: 475px) {
    .height--health {
        grid-area: height--health;
    }
    .weight--health {
        grid-area: weight--health;
    }
    .status--health {
        grid-area: status--health;
    }
    .cm--health {
        grid-area: cm--health;
    }
    .kg--health {
        grid-area: kg--health;
    }
    .healthiness--health {
        grid-area: healthiness--health;
    }
    .progress-health .health-content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'height--health cm--health' 'weight--health kg--health' 'status--health healthiness--health';
        align-items: center;
    }
    .progressGraphFromPC {
        width: minmax(100px, 400px);
    }
}

@media (max-width: 385px) {
    .progress-grid-container {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
    .progress-component-right-inner {
        align-items: center;
        column-gap: 0.3rem;
        padding: 0.5rem;
        border-radius: 0.7rem;
    }
}

/* ============================================= */

/* ============= / MEDIA QUERIES / ============= */

/* ============================================= */