.chat-container * {
    color: #272a2f;
}

.chat-container {
    height: calc(100vh - 64px);
    margin-left: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: none; */
}

.chat-days {
    display: flex;
    align-self: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: auto;
    width: max-content;
    height: max-content;
    padding: 0.1rem 0.85rem 0.1rem 0.85rem;
    border: 1px solid #9a9fa5;
    border-radius: 0.7rem;
    color: black;
    font-size: 0.75rem;
    background-color: #e5e5e5;
}

.chat-container-inner {
    width: clamp(200px, 80vw, 2200px);
    height: clamp(100px, 85vh, 1500px);
    background-color: #fff;
    box-shadow: 1rem 2rem 4rem rgba(0, 0, 0, 0.4);
    border-radius: 3rem;
    padding: 3rem;
}

.both-chats-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 2rem;
    padding-top: 2rem;
    height: calc(100% - 3rem);
}

.chat-container-inner .dm-chat-container {
    border: 2px solid #f4f4f4;
    border-radius: 2rem;
}

.all-chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: scroll;
}

.single-chat-container {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 2rem;
}

.single-chat-container>img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.single-chat-container:hover {
    background-color: #efefef;
}

.single-person-chat-info {
    width: 100%;
}

.single-person-chat-info-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}

.single-person-chat-info-top .last-msg-info {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.single-person-chat-info-top .last-msg-info span {
    color: #9a9fa5;
    font-weight: 500;
    font-size: 1.1rem;
}

.single-person-chat-info-top .last-msg-info .single-chat-notification {
    width: 20px;
    height: 20px;
    background-color: #2a86ff;
    border-radius: 50%;
}

.single-person-chat-info .single-chat-last-msg {
    color: #70767e;
    font-weight: 500;
    font-size: 1.2rem;
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.all-chat-container .search-message-input-container .searchcont,
.type-dm-msg-container .searchcont {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-message-input-container {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
    max-width: 90%;
}

.search-message-input-container input {
    background-color: transparent;
    border: none;
    outline: none;
    height: 100%;
    padding-left: 1rem;
    font-size: 1.2rem;
}

.search-message-input-container img {
    width: 20px;
}

.dm-chat-container {
    overflow: hidden;
}

.dm-chat-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    background-color: #f4f4f4;
    min-height: 100px;
}

.dm-chat-top .three-dots {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 3px;
}

.dm-chat-top .three-dots .dot {
    width: 5px;
    height: 5px;
    background-color: #272a2f;
    border-radius: 50%;
}

.dm-chats {
    max-height: calc(100% - 200px);
    min-height: calc(100% - 200px);
    overflow-y: auto;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    padding: 2rem;
}

.single-dm-container {
    display: flex;
    align-items: flex-start;
    column-gap: 1rem;
    margin-bottom: 2rem;
}

.single-dm-container img {
    width: 70px;
    height: 70px;
    border-radius: 35px;
}

.single-dm-top-info {
    display: flex;
    column-gap: 1rem;
}

.single-dm-top-info h3 {
    color: #6f767e;
    font-weight: 600;
    font-size: 1.2rem;
}

.single-dm-top-info span {
    color: #9a9fa5;
    font-weight: 600;
    font-size: 1.2rem;
}

.dm-msg {
    font-size: 1.1rem;
}

.type-dm-msg-container {
    height: 100px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.type-dm-msg-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    transform: translateY(-100%);
    background-image: linear-gradient( to top, rgba(255, 255, 255, 1), transparent);
    pointer-events: none;
    user-select: none;
}

.dm-chat-container .type-dm-msg-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dm-chat-container .search-message-input-container {
    display: inline-block;
    padding: 0.5rem;
}

.dm-chat-container .search-message-input-container button {
    background-color: #ff5a2b;
    color: #fff;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    cursor: pointer;
}

.dm-chat-container .search-message-input-container button:hover {
    background-color: #2a86ff;
}

.dm-chat-container .search-message-input-container button:active {
    transform: scale(0.9);
}

@media (max-width: 2000px) {
    .single-chat-container {
        border-radius: 1rem;
    }
    .single-chat-container>img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }
    .single-person-chat-info-top h2,
    .single-person-chat-info-top .last-msg-info span {
        font-size: 0.9rem;
    }
    .single-person-chat-info .single-chat-last-msg {
        width: 250px;
        font-size: 0.9rem;
    }
    .single-person-chat-info-top .last-msg-info .single-chat-notification {
        width: 15px;
        height: 15px;
    }
    .single-dm-container img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }
    .single-dm-top-info h3,
    .dm-msg {
        font-size: 1rem;
    }
    .single-dm-top-info span {
        font-size: 0.9rem;
    }
}

@media (max-width: 1700px) {
    .chat-container-inner {
        padding: 2rem;
        border-radius: 2rem;
    }
    .both-chats-container {
        column-gap: 0.5rem;
        padding-top: 0.5rem;
    }
    .single-person-chat-info-top {
        margin-bottom: 0.5rem;
    }
    .single-person-chat-info-top .last-msg-info .single-chat-notification {
        width: 10px;
        height: 10px;
    }
    .search-message-input-container {
        width: minmax(100px, 50%, 400px);
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
    }
    .search-message-input-container input {
        padding-left: 0.5rem;
        font-size: 1rem;
    }
    .search-message-input-container img {
        width: 15px;
    }
    .chat-container-inner .dm-chat-container {
        border-radius: 1rem;
    }
    .dm-chat-top {
        min-height: 60px;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 0;
        padding-top: 0;
    }
    .dm-chat-top h1 {
        font-size: 1.5rem;
    }
    .dm-chat-top .three-dots {
        width: 30px;
        height: 30px;
        column-gap: 2px;
    }
    .dm-chats {
        max-height: calc(100% - 120px);
        min-height: calc(100% - 120px);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1rem;
    }
    .single-dm-container {
        column-gap: 0.5rem;
        margin-bottom: 1rem;
    }
    .single-dm-container img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
    }
    .single-dm-top-info {
        align-items: center;
    }
    .single-dm-top-info h3 {
        font-size: 0.9rem;
    }
    .single-dm-top-info span {
        font-size: 0.8rem;
    }
    .dm-msg {
        font-size: 0.8rem;
    }
    .search-message-input-container input {
        width: 200px;
    }
    .type-dm-msg-container {
        height: 60px;
    }
    .dm-chat-container .search-message-input-container {
        padding: 0.3rem;
    }
    .dm-chat-container .search-message-input-container input {
        font-size: 0.8rem;
    }
    .dm-chat-container .search-message-input-container button {
        padding: 0.2rem 0.5rem;
        margin-left: 1rem;
        border-radius: 0.3rem;
        font-size: 0.8rem;
    }
}

@media (max-width: 1300px) {
    .chat-container-inner {
        padding: 1.5rem;
        border-radius: 1.5rem;
    }
    .chat-container-inner>h1 {
        font-size: 1.5rem;
    }
    .both-chats-container {
        padding-top: 0.3rem;
    }
    .single-person-chat-info-top {
        margin-bottom: 0rem;
    }
    .single-chat-container {
        column-gap: 0.5rem;
    }
    .single-chat-container>img {
        width: 45px;
        height: 45px;
        border-radius: 22px;
    }
    .single-person-chat-info-top .last-msg-info span {
        font-size: 0.8rem;
    }
    .single-person-chat-info .single-chat-last-msg {
        font-size: 0.8rem;
        width: 200px;
    }
    .search-message-input-container input {
        font-size: 0.8rem;
    }
    .dm-chat-top h1 {
        font-size: 1.3rem;
    }
}

@media (max-width: 1000px) {
    .single-person-chat-info-top h2 {
        font-size: 0.8rem;
    }
    .single-chat-container {
        padding: 0.5rem;
        border-radius: 0.5rem;
    }
}

@media (max-width: 790px) {
    .both-chats-container {
        grid-template-columns: 1fr;
    }
    .dm-chat-container {
        display: none;
    }
    .all-chat-container {
        margin-top: 1rem;
        overflow-y: scroll;
        /* display: none; */
    }
    .single-person-chat-info-top {
        margin-bottom: 0.5rem;
    }
    .single-chat-container {
        column-gap: 1rem;
    }
    .single-chat-container>img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }
    .single-person-chat-info .single-chat-last-msg {
        width: 450px;
    }
}

@media (max-width: 730px) {
    .single-person-chat-info .single-chat-last-msg {
        width: 400px;
    }
}

@media (max-width: 665px) {
    .single-person-chat-info .single-chat-last-msg {
        width: 350px;
    }
}

@media (max-width: 599px) {
    .chat-container {
        margin-left: 0;
    }
    .single-person-chat-info .single-chat-last-msg {
        width: 300px;
    }
}

@media (max-width: 540px) {
    .single-person-chat-info .single-chat-last-msg {
        width: 250px;
    }
}

@media (max-width: 470px) {
    .chat-container-inner {
        padding: 1rem;
        width: clamp(200px, 90vw, 2200px);
    }
    .single-person-chat-info .single-chat-last-msg {
        width: 200px;
    }
}

/* ========================================= */

/* ========== / CHAT BACKGROUND / ========== */

/* ========================================= */

@media (max-width: 2000px) {
    .left-sand-astro {
        width: 1400px !important;
    }
    .right-sand-astro {
        width: 1400px !important;
    }
}

@media (max-width: 1700px) {
    .left-sand-astro {
        width: 1000px !important;
        bottom: -13rem !important;
        left: -20rem !important;
    }
    .right-sand-astro {
        width: 1000px !important;
        bottom: -13rem !important;
        right: -23rem !important;
    }
}

@media (max-width: 830px) {
    .middle-sand-astro {
        display: none;
    }
}

@media (max-width: 599px) {
    .left-sand-astro {
        width: 700px !important;
        bottom: 5rem !important;
        left: -17rem !important;
    }
    .right-sand-astro {
        width: 700px !important;
        bottom: 5rem !important;
        right: -17rem !important;
    }
}

@media (max-width: 470px) {
    .left-sand-astro {
        left: -19rem !important;
    }
    .right-sand-astro {
        right: -19rem !important;
    }
}

/* ========================================= */

/* ========== / CHAT BACKGROUND / ========== */

/* ========================================= */

.dm_arrow {
    display: none !important;
}

@media (max-width: 790px) {
    .remove {
        display: none;
    }
    .add-dm {
        display: flex;
    }
    .add-all-chat {
        display: block;
    }
    .dm_arrow {
        display: block !important;
    }
}