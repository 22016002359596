.admin-settings-container {
    background-color: #fff;
    width: clamp(100px, 75%, 2000px);
    height: max-content;
    border-radius: 3rem;
    box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3), 0 0 1rem rgba(0, 0, 0, 0.3);
    padding: 3rem;
}

.admin-page-content-container {
    height: calc(100vh - 64px);
    margin-left: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-btns {
    display: flex;
    width: 100%;
}

@media screen and (max-width: 599px) {
    .admin-settings-container {
        background-color: #fff;
        width: clamp(100px, 90%, 2000px);
        height: max-content;
        border-radius: 3rem;
        box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3), 0 0 1rem rgba(0, 0, 0, 0.3);
        padding: 3rem;
        margin-left: 20px;
    }
    .admin-page-content-container {
        margin-left: 0;
    }
}

@media (max-height: 620px) {
    .admin-page-content-container {
        align-items: flex-start;
        height: clamp(200px, 95%, 1000px);
    }
}

@media (max-width: 680px) {
    .admin-page-content-container {
        align-items: flex-start;
        padding: 2rem 0;
        height: auto;
    }
}

@media screen and (max-width: 690px) {
    .filers {
        font-size: 0.7rem !important;
    }
    .upload-btns {
        flex-direction: column;
    }
}

@media (max-height: 1020px) {
    .admin-settings-container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.admin-settings-title {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.admin-page-orange-rect {
    width: 20px;
    height: 40px;
    border-radius: 0.3rem;
    background-color: #ff592c;
}

.admin-page-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column-gap: 2rem;
}

.admin-page-content .form-group .form-group-desciption-box input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 4rem;
    width: auto;
    font-size: small;
    border-radius: 1rem;
}

.admin-settings-btn-schedule {
    background-color: #ff592c !important;
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
    width: auto;
    padding: 1rem !important;
    margin-bottom: 1rem !important;
    border-radius: 1rem !important;
}

.admin-settings-btn-schedule div {
    width: 100%;
    text-align: center;
    font-weight: 700 !important;
}

.admin-page-content .form-group .class-dropdown .select-dialog {
    height: 2.5rem;
    background-color: #fff;
    border-radius: 1rem;
    font-size: 0.8rem;
    padding: 5px 10px;
    margin-left: -0.5rem;
    width: 10rem;
}

.form-grp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-grp button {
    align-self: center;
    font-family: 'Poppins', sans-serif;
    padding: 0.5rem 3rem;
    font-size: 1.2rem;
    background-color: #ff592c;
    border: none;
    color: #fff;
    font-weight: 600;
    border-radius: 1.5rem;
    box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.3);
}

@media (max-width: 650px) {
    .form-grp button {
        align-self: center;
        font-family: 'Poppins', sans-serif;
        padding: 0.5rem 1.5rem;
        font-size: 0.875rem;
        background-color: #ff592c;
        border: none;
        color: #fff;
        font-weight: 500;
        border-radius: 80px;
        box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.3);
        min-width: 64px;
    }
}

.admin-page-content .form-group .form-group-heading {
    width: 100%;
    font-weight: 500 !important;
}

.admin-page-content .form-group .date-field {
    padding: 5px 10px;
    border-radius: 1rem;
    border: 1px ridge black;
    font-size: 0.8rem;
    height: auto;
}

.admin-page-content .form-group .file-upload>input[type='file'] {
    display: none;
}

.admin-page-content .form-group .file-upload img {
    width: 17px;
    cursor: pointer;
    height: 15px;
    margin-bottom: -1px;
}

.file-upload {
    width: max-content;
    display: flex;
    height: max-content;
}

.filers {
    width: 100%;
    display: flex;
    font-size: 0.75rem;
}

.admin-page-content .form-group .file-upload label {
    background-color: #ff592c;
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    border: 0.5px ridge black;
    font-weight: 550;
    font-size: 0.8rem !important;
    width: max-content !important;
    color: white;
}

.admin-page-content .form-group .file-upload label:hover {
    background-color: #1976d2;
    color: white;
    cursor: pointer;
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
}

.success-img {
    align-self: center;
    object-fit: contain;
    height: 5rem;
    width: 5rem;
}

.mod_head {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: dimgray;
    font-size: 1rem;
    width: 80%;
    padding: 2% 5% 2% 5%;
}

.mod_data {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #4d4d4d;
    font-size: 1.2rem;
    width: 70%;
    padding: 2% 5% 2% 5%;
}