.toggel-bar {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border-bottom: 3px solid #f4f4f4;
}

.dot {
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: -0.2rem;
}

.rewards-toggel {
    background-color: #fcfcfc;
    border: none;
    color: rgb(0, 0, 0);
    padding: 15px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    margin-bottom: -1px;
    transition-duration: 0.4s;
    cursor: pointer;
    align-items: center;
}

.toggel-bar .rewards-toggel:hover {
    border-bottom: 3px solid black;
}

.toggel-bar .rewards-toggel:active {
    border-bottom: 3px solid black;
}

.product-display {
    flex-wrap: wrap;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    width: 100%;
}

.product-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 300px;
    margin-top: 2rem;
}

.product-image {
    display: block;
    width: auto;
    height: auto;
    border-radius: 10%;
}

.product-cards {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 200px;
    margin-left: 30%;
}

.product-details {
    margin: 16px;
    text-align: center;
}

.product-name {
    margin-top: 0;
    margin-bottom: 8px;
}

.product-description {
    margin-top: 0;
    margin-bottom: 16px;
}

.product-price {
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: bold;
}

.product-claim {
    background-color: #ff592c;
    border: none;
    border-radius: 8px;
    padding: 3% 8% 3% 8%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.product-hash {
    margin-right: 2%;
    margin-left: -6%;
    padding: 1% 3% 1% 3%;
    background-color: #9f73e6;
    text-decoration-color: #9f73e6;
    border-radius: 4px;
    border: 2px;
    font-size: 1vw;
}

.product-sp {
    margin-left: 2.5%;
    padding: 0.4% 2.4% 0.4% 2.4%;
    background-color: white;
    color: #9f73e6;
    border-radius: 4px;
    border: 2px solid #9f73e6;
    font-size: 1vw;
}

.no-data::before {
    content: 'No Data Available';
    font-weight: bold;
    font-size: 4vw;
    color: #999;
    align-content: center;
    margin-top: 5vh;
    margin-left: 30vw;
}

@media screen and (max-width: 667px) {
    .product-claim {
        font-size: 3.5vw;
    }
    .product-hash {
        font-size: 2vw;
    }
    .product-sp {
        font-size: 1.2vw;
    }
    .product-cards {
        margin-left: 10%;
    }
    .no-data::before {
        margin-left: 5vw;
    }
}

@media screen and (max-width: 884px) {
    .product-display {
        grid-template-columns: repeat(2, 1fr);
    }
    .product-claim {
        font-size: 3.5vw;
    }
    .product-hash {
        font-size: 2.5vw;
    }
    .product-sp {
        font-size: 2.5vw;
    }
    .product-cards {
        margin-left: 10%;
    }
    .no-data::before {
        margin-left: 5vw;
    }
}

@media screen and (max-width: 1180px) {
    .product-claim {
        font-size: 3.5vw;
    }
    .product-hash {
        font-size: 2.5vw;
    }
    .product-sp {
        font-size: 2.5vw;
    }
    .product-cards {
        margin-left: 30%;
    }
    .no-data::before {
        margin-left: 25vw;
    }
}

@media screen and (max-width: 540px) and (max-height: 720px) {
    .product-claim {
        font-size: 3.5vw;
    }
    .product-hash {
        font-size: 2.5vw;
    }
    .product-sp {
        font-size: 2.5vw;
    }
    .product-cards {
        margin-left: 25%;
    }
    .no-data::before {
        margin-left: 3vw;
    }
}

@media screen and (max-width: 375px) and (max-height: 667px) {
    .product-claim {
        font-size: 2.5vw;
        margin-bottom: 1rem;
    }
    .product-hash {
        font-size: 1.5vw;
        margin-bottom: 1rem;
    }
    .product-sp {
        font-size: 1.5vw;
        margin-bottom: 1rem;
    }
    .product-cards {
        margin-left: 0%;
    }
    .no-data::before {
        margin-left: 25vw;
        margin-top: 3vh;
    }
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
    .product-claim {
        font-size: 2.5vw;
        margin-bottom: 0.5rem;
    }
    .product-hash {
        font-size: 1.5vw;
        margin-bottom: 0.5rem;
    }
    .product-sp {
        font-size: 1.5vw;
        margin-bottom: 0.5rem;
    }
    .product-cards {
        margin-left: 32%;
    }
    .no-data::before {
        margin-left: 25vw;
        margin-top: 3vh;
    }
}

@media screen and (max-width: 280px) and (max-height: 653px) {
    .product-claim {
        font-size: 3vw;
    }
    .product-hash {
        font-size: 2vw;
    }
    .product-sp {
        font-size: 2vw;
    }
    .product-cards {
        margin-left: 32%;
    }
    .no-data::before {
        margin-left: 25vw;
        margin-top: 3vh;
    }
}

@media screen and (max-width: 540px) and (max-height: 720px) {
    .product-claim {
        font-size: 3vw;
        margin-bottom: 0.5rem;
    }
    .product-hash {
        font-size: 2vw;
        margin-bottom: 0.5rem;
    }
    .product-sp {
        font-size: 2vw;
    }
    .product-cards {
        margin-left: 25%;
    }
    .no-data::before {
        margin-left: 2vw;
        margin-top: 3vh;
    }
}

@media screen and (max-width: 820px) and (max-height: 1180px) {
    .product-claim {
        font-size: 3.5vw;
        margin-bottom: 0.5rem;
    }
    .product-hash {
        font-size: 2.5vw;
        margin-bottom: 0.5rem;
    }
    .product-sp {
        font-size: 2.5vw;
    }
    .product-cards {
        margin-left: 30%;
    }
    .no-data::before {
        margin-left: 25vw;
        margin-top: 3vh;
    }
}