.class-progress-content {
    margin: 0 auto 0 60px;
    padding: 1rem 3rem 2rem;
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5rem;
}

.class-progress-content .top-three-rankers {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8rem;
    background-color: #fff;
    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.3);
    padding: 5rem 2rem;
}

.class-progress-content .top-left {
    width: clamp(100px, 60%, 1500px);
    margin: 0 auto;
}

.class-progress-content .top-performers-container {
    position: relative;
    top: 0;
    margin-top: 1rem;
}

.class-progress-article {
    background-color: #fff;
    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.3);
    padding: 5rem 2rem;
    border-radius: 3rem;
}

.class-progress-content .progressGraphFromPC {
    width: clamp(100px, 100%, 2200px);
    height: clamp(100px, 80vh, 700px);
}

.class-progress-content .item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.class-progress-top {
    max-width: 1700px;
    margin: 0 auto;
}

@media (max-height: 760px) {
    .class-progress-content {
        row-gap: 2rem;
    }
    .class-progress-content .progressGraphFromPC {
        height: 350px;
    }
}

@media (max-height: 625px) {
    .class-progress-content .progressGraphFromPC {
        height: 300px;
    }
}

@media (max-width: 1700px) {
    .class-progress-content {
        row-gap: 2rem;
    }
}

@media (max-width: 1410px) {
    .class-progress-content .progress-component-right {
        width: 30%;
    }
}

@media (max-width: 1000px) {
    .class-progress-content .progress-component-right {
        width: 100%;
    }
    .class-progress-content .graph-container {
        max-height: 350px;
    }
    .class-progress-content .progressGraphFromPC {
        height: auto;
        padding-bottom: 1rem;
    }
}

@media (max-width: 780px) {
    .class-progress-content .top-left {
        width: 100%;
    }
}

@media (max-width: 740px) {
    .class-progress-content {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .class-progress-content .progress-component-right-inner {
        padding: 1rem;
        column-gap: 0.5rem;
    }
    .class-progress-content .progress-component-right-inner img {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 599px) {
    /* .class-progress-content .progress-component-right {
        margin-top: 0;
    } */
    .class-progress-content {
        margin-left: 0;
        justify-content: flex-start;
        height: auto;
    }
    .class-progress-content .progressGraphFromPC {
        padding-bottom: 0rem;
    }
}

@media (max-width: 440px) {
    .class-progress-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .class-progress-content .top-performers-container .item img {
        width: 50px;
    }
    .class-progress-content .top-performers-container .item span {
        font-size: 0.8rem;
        margin-top: 0.3rem;
    }
}

@media (max-width: 410px) {
    .class-progress-content .progress-component-right-inner {
        padding: 0.5rem;
        border-radius: 0.5rem;
    }
    .class-progress-content .progress-component-right-inner img {
        width: 25px;
        height: 25px;
    }
    .class-progress-content .progressGraphFromPC .progress-article-navigation .left {
        column-gap: 0.5rem;
    }
    .class-progress-content .article-navigation h1,
    .class-progress-content .progressGraphFromPC .progress-article-navigation h1 {
        font-weight: 500;
        font-size: 1rem;
    }
    .class-progress-content .article-navigation .orange-rect,
    .class-progress-content .progressGraphFromPC .progress-article-navigation .progress-orange-rect {
        width: 13px;
        height: 25px;
    }
}

/* ========================== */

/* ===== / BACKGROUND / ===== */

/* ========================== */

@media (max-width: 2050px) {
    .class-progress .clas-progress-bcg-1,
    .class-progress .clas-progress-bcg-2 {
        top: -5rem !important;
    }
}

@media (max-width: 1700px) {
    .class-progress .clas-progress-bcg-1 {
        height: 90% !important;
        left: -10rem !important;
        top: -2rem !important;
    }
    .class-progress .clas-progress-bcg-2 {
        height: 90% !important;
        right: -15rem !important;
        top: -2rem !important;
    }
}

@media (max-width: 1000px) {
    .class-progress .clas-progress-bcg-1 {
        height: 600px !important;
        top: 5rem !important;
    }
    .class-progress .clas-progress-bcg-2 {
        height: 600px !important;
        top: 5rem !important;
    }
}

@media (max-width: 599px) {
    .class-progress .clas-progress-bcg-1 {
        height: 300px !important;
        top: 12rem !important;
        left: -5rem !important;
    }
    .class-progress .clas-progress-bcg-2 {
        height: 300px !important;
        top: 12rem !important;
        right: -7rem !important;
    }
}

/* ========================== */

/* ===== / BACKGROUND / ===== */

/* ========================== */