.hightlights .highlights-grid {
  display: grid;
  grid-template-columns: minmax(100px, 450px) minmax(200px, 1400px);
  gap: 3rem;
  margin-left: 60px;
  padding: 10px 40px 0;
  color: #23262f;
  height: calc(100vh - 200px);
  max-height: 1200px;
}

.hightlights .highlights-grid > * {
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  padding: 3rem;
}

.hightlights .top-bar,
.hightlights .top-bar .left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.hightlights .orange-box {
  width: 20px;
  height: 40px;
  background-color: #ff592b;
  border-radius: 0.5rem;
}

.hightlights .today-highlights-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hightlights .my-rank {
  margin: 0 0 3rem;
  display: flex;
  flex-direction: column;
  width: 500px;
}

.hightlights .my-rank .top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hightlights .my-rank-content {
  display: flex;
  justify-content: space-between;
}

.hightlights .my-rank-content .left {
  display: flex;
  flex-direction: column;
}

.hightlights .today-highlights-content .bottom {
  display: grid;
  grid-template-columns: minmax(100px, 300px) minmax(100px, 300px);
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
}

.hightlights .pie-chart {
  margin-top: 0.5rem;
  width: 100%;
  margin: 0.5rem auto 0;
}

.hightlights .bottom-box,
.hightlights .my-rank {
  box-shadow: 0 1rem 4rem rgba(0, 0, 0, 0.2);
  border-radius: 3rem;
  padding: 2rem;
}

.hightlights .bottom-box .top-bar h2,
.hightlights .my-rank .top-bar h2 {
  font-size: 1.2rem;
}

.hightlights .my-rank-content {
  margin-top: 1rem;
}

.hightlights .my-rank-content .right {
  background-color: rgba(0, 0, 0, 0.05);
  /* aspect-ratio: 1.2 / 1; */
  width: 40%;
  border-radius: 3rem;
  display: grid;
  place-content: center;
  font-size: 1.8rem;
}

.hightlights .my-rank-content .left p {
  font-size: 1.1rem;
}

.hightlights .box-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.today-highlights-container .top-bar h2 {
  font-size: 1.4rem;
}

.hightlights .today-session-box {
  font-weight: 600;
  outline: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
}

.hightlights .today-highlights-session > h1 {
  margin: 3rem 0;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.hightlights .today-highlights-session > h1 * {
  font-size: 1.8rem;
}

.hightlights .black-div {
  background-color: #23262f;
  color: #fff;
  text-align: center;
  border-radius: 50vw;
  padding: 0.5rem 0;
}

.hightlights .white-div-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.hightlights .white-div {
  border: 2px solid #cebfbf;
  padding: 0.5rem 2rem;
  border-radius: 50vw;
}

.list {
  display: grid;
  grid-template-columns: 2fr 1fr;
  row-gap: 2rem;
  column-gap: 1rem;
  margin-top: 2rem;
}

.list .left {
  color: #787e8d;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.divider {
  width: 100%;
  height: 2px;
  background-color: #9095a7;
  margin: 8rem 0 2rem;
}

.my-rank-content .left img {
  width: 70px;
  height: 70px;
  border-radius: 35px;
}

@media (max-height: 945px) {
  .my-rank-content .left img {
    width: 70px;
    height: 70px;
    border-radius: 35px;
  }
  .hightlights .my-rank-content .right h1 {
    font-size: 1.3rem !important;
    border-radius: 2rem;
  }
  .hightlights .bottom-box,
  .hightlights .my-rank {
    padding-bottom: 1rem;
    border-radius: 2rem;
  }
  .hightlights .divider {
    margin: 3rem 0 2rem;
  }
  .hightlights .today-highlights-session > h1 {
    margin: 1.5rem 0;
  }
  .hightlights .white-div-container {
    margin: 1rem 0 0;
  }
}

@media (max-height: 835px) {
  .hightlights .divider {
    margin: 2rem 0 1rem;
  }
  .hightlights .today-highlights-session > h1 {
    margin: 1rem 0;
  }
  .hightlights .pie-chart {
    width: 60%;
  }
  .hightlights .my-rank {
    margin: 0 0 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .hightlights .today-highlights-content .bottom {
    gap: 1rem;
  }
}

@media (max-height: 735px) {
  .hightlights .highlights-grid > * {
    padding: 1.5rem 3rem;
  }
  .hightlights .pie-chart {
    width: 50%;
  }
  .hightlights .bottom-box {
    padding: 1rem 2rem;
  }
  .hightlights .divider {
    margin: 1rem 0;
  }
  .hightlights .list {
    row-gap: 1rem;
  }
}

@media (max-width: 1410px) {
  .hightlights .top-bar h2 {
    font-size: 0.9rem;
  }
  .hightlights .orange-box {
    width: 15px;
    height: 30px;
    border-radius: 0.3rem;
  }
  .hightlights .top-bar,
  .hightlights .top-bar .left {
    column-gap: 0.5rem;
  }
}

@media (max-width: 1150px) {
  .hightlights .my-rank {
    width: 350px;
  }
  .hightlights .my-rank-content .left p {
    font-size: 0.9rem;
  }
  .hightlights .today-highlights-session > h1 * {
    font-size: 1.3rem;
  }
  .hightlights .highlights-grid > * {
    padding: 1.5rem 2rem;
  }
  .hightlights .bottom-box {
    padding: 2rem 1rem 0.5rem;
  }
}

@media (max-width: 980px) {
  .hightlights .highlights-grid .today-highlights-container {
    padding: 1rem !important;
  }
  /* .hightlights .today-highlights-content .bottom {
    grid-template-columns: minmax(100px, 300px) minmax(100px, 300px);
  } */
}

@media (max-width: 910px) {
  .today-highlights-container {
    padding-bottom: 2rem !important;
  }
  .hightlights .highlights-grid {
    justify-content: center;
    grid-template-columns: 600px;
    padding-bottom: 2rem;
  }
  .hightlights .highlights-grid > * {
    padding: 3rem;
  }
  .hightlights .my-rank {
    margin-top: 2rem;
  }
  .hightlights .today-highlights-content .bottom {
    grid-template-columns: minmax(150px, 250px);
  }
  .hightlights .bottom-box .top-bar h2 {
    font-size: 1rem;
  }
}

@media (max-width: 700px) {
  .hightlights .highlights-grid {
    grid-template-columns: 1fr;
    padding: 1rem 1rem 2rem;
  }
  .hightlights .highlights-grid {
    gap: 1rem;
  }
  .hightlights .my-rank {
    width: 300px;
    padding: 1rem 1rem 0.5rem;
    border-radius: 1rem;
  }
  .hightlights .bottom-box {
    border-radius: 1rem;
  }
  .my-rank-content .left img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  .hightlights .my-rank-content .left p {
    font-size: 0.8rem;
    text-align: center;
  }
  .hightlights .my-rank-content .right {
    border-radius: 1rem;
  }
  .hightlights .my-rank-content .right h1 {
    font-size: 1.2rem !important;
  }
  .hightlights .orange-box {
    width: 10px;
    height: 25px;
    border-radius: 0.2rem;
  }
  .hightlights .today-highlights-content .bottom {
    gap: 1rem;
    padding-bottom: 1rem;
  }
  .hightlights .my-rank-top-select {
    width: 80px;
  }
}

@media (max-width: 599px) {
  .hightlights .highlights-grid {
    margin-left: 0;
  }
}

/* @media (max-width: 435px) {
  .hightlights .highlights-grid {
    grid-template-columns: 1fr;
    padding: 10px 15px 0;
  }
  .hightlights .highlights-grid > * {
    padding: 3rem 1rem;
  }
  .hightlights .pie-chart {
    width: 60%;
  }
  .highlights .my-rank {
    width: 150px !important;
  }
} */

/* @media (max-width: 875px) {
  .hightlights .highlights-grid {
    gap: 1rem;
  }

  .hightlights .my-rank {
    width: 250px;
    padding: 1rem 1rem .5rem;
    border-radius: 1rem;
  }

  .hightlights .bottom-box {
    border-radius: 1rem;
  }

  .hightlights .my-rank-content .left img {
    width: 40px;
  }

  .hightlights .my-rank-content .left p {
    font-size: .8rem;
  }

  .hightlights .my-rank-content .right {
    border-radius: 1rem;
  }

  .hightlights .my-rank-content .right h1 {
    font-size: 1.2rem !important;
  }

  .hightlights .orange-box {
    width: 10px;
    height: 25px;
    border-radius: .2rem;
  }

  .hightlights .today-highlights-content .bottom {
    gap: 1rem;
  }

  .hightlights .my-rank-top-select {
    width: 80px;
  }
}

@media (max-width: 770px) {
  .hightlights .highlights-grid {
    padding: 10px 20px 0;
  }

  .hightlights .highlights-grid>* {
    padding: 1.5rem 1rem;
  }

  .hightlights .today-highlights-session>h1 * {
    font-size: 1.1rem;
  }

  .hightlights .black-div,
  .hightlights .white-div {
    font-size: .8rem;
  }
}

@media (max-width: 666px) {
  .hightlights .bottom-box .top-bar h2 {
    font-size: .8rem;
  }
} */

/* @media (max-width: 600px) {
  .hightlights .highlights-grid {
    grid-template-columns: 1fr;
    padding-bottom: 2rem;
  }
  .hightlights .highlights-grid > * {
    padding: 3rem;
  }
  .hightlights .my-rank {
    margin-top: 2rem;
  }
  .hightlights .today-highlights-content .bottom {
    grid-template-columns: minmax(150px, 250px);
  }

  .hightlights .bottom-box .top-bar h2 {
    font-size: 1rem;
  }
} */

@media (max-width: 435px) {
  .hightlights .highlights-grid {
    padding: 10px 15px 0;
  }
  .hightlights .highlights-grid > * {
    padding: 3rem 1rem;
  }
  .hightlights .pie-chart {
    width: 60%;
  }
  .hightlights .my-rank {
    width: 250px;
  }
}

@media (max-width: 375px) {
}

@media (max-width: 315px) {
  .hightlights .my-rank {
    width: 150px;
  }
}

/* .avtarhi {
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
} */
