.container-demo {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    /* max-width: 1200px; */
    margin: 0 auto;
    height: 100vh;
    background: #000;
    background-clip: padding-box !important;
    border: solid 5px transparent !important;
    border-radius: 1em;
    overflow: hidden;
}

.icons-cont {
    width: 95%;
    top: 5px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: absolute;
}

.svg_icons {
    height: 2em !important;
    width: 2em !important;
}

.progress-demo {
    height: fit-content;
    width: 10vw;
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .container-demo {
        flex-direction: column;
        gap: 0;
        height: 120vh;
    }
    /* .progress-demo {
      height: fit-content;
      width: fit-content;
      top: 38.5vh;
    } */
}

.box1 {
    flex: 1;
    margin: 0;
    padding: 10px 0 10px 0;
    border: 3px solid #000000;
    box-sizing: border-box;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
}

.box2 {
    flex: 1;
    margin: 0 0 0 3px;
    padding: 12px 12px 12px 0;
    box-sizing: border-box;
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
}

iframe {
    width: 100% !important;
    height: 100% !important;
    border: black;
    border-radius: 1rem;
}

canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: 1rem;
}

.box2 canvas .canvas-class {
    width: 100% !important;
    height: 100% !important;
}

.Player {
    overflow: hidden;
}

.player-wrapper {
    width: auto;
    height: auto;
}

.react-player>div {
    position: absolute !important;
    top: 0;
    left: 0;
}

.react-player video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.prog_bottom_bar {
    position: absolute;
    bottom: 5px;
    height: fit-content;
    width: 100%;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.assessment_bottom {
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    bottom: 2%;
    width: 100%;
}