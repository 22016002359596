.loginbox_bg {
  display: flex;
  position: absolute;
  margin: 400px 500px 400px 550px;
  background: #ffffff;
  box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  height: 254px;
  width: 431px;
}

.passbox-bg {
  margin: -30px 20px 20px -48px;
  display: flex;
  position: absolute;
  background: #ffffff;
  box-shadow: 2px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  width: 0%;
}

.loginbox_1 {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.passbox_1 {
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.cutbtn {
  margin-top: -30px;
  margin-left: 120px;
}
.cnlBtn {
  margin-left: 200px;
  border-radius: 90px;
  border-color: #ffffff;
  width: 19px;
  height: 19px;
}

.log_s {
  width: 74px;
  height: 40px;
  margin-top: 20px;
  margin-left: 125px;
  background: #ff592c;
  border-radius: 90px;
  border-color: #ff592c;
  color: #ffffff;
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
}

.log-head {
  font-family: 'Poppins';
  font-weight: 600;
}

.logg-head {
  margin-top: -20px;
  font-family: 'Poppins';
  font-weight: 600;
}

#username {
  background: #fcfcfd;
  width: 297px;
  height: 46px;
  margin-top: 25px;
  border: 2px solid #e6e8ec;
  border-radius: 90px;
  font-family: 'Poppins';
}

#password {
  background: #fcfcfd;
  width: 297px;
  height: 46px;
  margin-top: 35px;
  border: 2px solid #e6e8ec;
  border-radius: 90px;
  font-family: 'Poppins';
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 30px;
}
