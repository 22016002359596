.progress-vertical {
    width: 25px;
    height: 300px;
    background: white;
    /* position: absolute; */
    padding: 3px;
    bottom: 0;
}

.progress-bar {
    position: relative;
    width: 100%;
}

@media (max-width: 600px) {
    .progress-vertical {
        width: 20px;
        height: 300px;
        /* position: absolute; */
        padding: 3px;
        transform: rotateZ(90deg);
        top: 56%;
    }
}