.img_container_berg {
    width: 25%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0;
    cursor: pointer;
}

.img_container_berg div {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: -0.01em;
}

.img_container_berg img {
    object-fit: contain;
    width: 55%;
}