.schedule-content {
    display: grid;
    grid-template-columns: 1fr minmax(100px, 600px);
    grid-template-rows: 2fr 1fr;
    column-gap: 10rem;
    row-gap: 3rem;
    margin-left: 60px;
    margin-top: 2rem;
    padding: 10px 15rem 0;
    height: calc(100vh - 200px);
    max-height: 1200px;
    max-width: 2300px;
    color: #353945;
}

.join-btn {
    margin-left: 40%;
}

.nextsesleft {
    margin-top: 5%;
    margin-left: 20%;
    margin-bottom: 5%;
    color: #787e8d;
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.rightdate {
    margin-left: 20%;
}

.rightstart {
    margin-left: 13.5%;
}

.calendar-container,
.today-session-container,
.tomorrow-session-container {
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.3);
    border-radius: 3rem;
    padding: 3rem;
}

.today-session-container {
    grid-row: span 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 5rem;
}

.today-session-box {
    font-size: 1.4rem;
}

.list-container {
    margin-top: 5rem !important;
    row-gap: 5rem !important;
    font-size: 1.3rem;
}

.list-container .right {
    font-weight: 500;
}

.schedule-divider {
    width: 100%;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.3);
    margin: 6rem 0 2rem !important;
}

.today-session-container>p {
    font-size: 1.3rem;
}

.join-session-btn-container {
    margin-top: 4rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.join-session-btn {
    padding: 0.5rem 4rem !important;
    text-transform: unset !important;
}

.today-session-container .box-container,
.tomorrow-session-container .box-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.today-session-container .box-container .today-session-box,
.tomorrow-session-container .box-container .today-session-box {
    display: inline-block;
    outline: 2px solid rgba(0, 0, 0, 0.3);
    padding: 0.3rem 2rem;
    border-radius: 0.5rem;
    font-weight: 600;
}

/* =============================== */

/* ====== TOMORROW SESSION ======= */

/* =============================== */

.tomorrow-session-container .box-container {
    justify-content: flex-start;
}

.tomorrow-session-container .list-container {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    row-gap: 2rem !important;
    margin-top: 2rem !important;
}

.tomorrow-session-container .schedule-divider {
    margin: 1rem 0 !important;
}

.tomorrow-session-container p {
    font-size: 1.3rem;
}

/* =============================== */

/* ====== TOMORROW SESSION ======= */

/* =============================== */

@media (max-height: 1260px) {
    .join-session-btn-container {
        margin-top: 2rem !important;
    }
    .schedule-content {
        height: calc(100vh - 150px);
    }
    .today-session-container .schedule-divider {
        margin: 3rem 0 2rem !important;
    }
    .today-session-container .list-container {
        row-gap: 4rem !important;
    }
    .calendar-container,
    .tomorrow-session-container {
        padding: 2rem 3rem 1rem;
    }
}

@media (max-height: 1050px) {
    .schedule-content {
        row-gap: 2rem;
        /* column-gap: 2rem; */
    }
    /* .today-session-container {
    padding: 2rem 3rem;
  } */
    .today-session-container .list-container {
        row-gap: 3rem !important;
    }
    .today-session-container .schedule-divider {
        margin: 1.5rem 0 2rem !important;
    }
    .tomorrow-session-container .list-container {
        row-gap: 1rem !important;
        margin-top: 1rem !important;
    }
    .tomorrow-session-container .schedule-divider {
        margin: 0.5rem 0 !important;
    }
    .calendar-container,
    .tomorrow-session-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

@media (max-height: 940px) {
    .today-session-container .list-container {
        row-gap: 2rem !important;
        margin-top: 2rem !important;
    }
}

@media (max-height: 885px) {
    .today-session-container .list-container {
        row-gap: 1.5rem !important;
        margin-top: 2rem !important;
    }
}

@media (max-height: 810px) {
    .calendar-container,
    .today-session-container,
    .tomorrow-session-container {
        border-radius: 2rem;
    }
}

@media (max-height: 780px) {
    .schedule-content {
        gap: 1rem;
    }
    .join-session-btn-container {
        margin-top: 1rem !important;
    }
    .today-session-container .schedule-divider {
        margin: 0.5rem 0 !important;
    }
    .calendar-container,
    .today-session-container,
    .tomorrow-session-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

/* ===================== */

/* ===== MAX WIDTH ===== */

/* ===================== */

@media (max-width: 1990px) {
    .schedule-content {
        padding: 10px 10rem 0;
    }
}

@media (max-width: 1610px) {
    .today-session-container {
        padding: 3rem 4rem;
    }
    .schedule-content {
        grid-template-columns: 2fr minmax(100px, 450px);
        justify-content: center;
    }
    .calendar-container,
    .tomorrow-session-container {
        padding-right: 2rem;
        padding-left: 2rem;
    }
    .today-session-box,
    .tomorrow-session-container p {
        font-size: 0.9rem !important;
    }
    .tomorrow-session-container .list-container {
        grid-template-columns: 1fr 0.5fr 1fr 1fr;
        font-size: 0.9rem !important;
    }
    .tomorrow-session-container p {
        font-size: 0.9rem;
    }
    .today-session-container .list-container,
    .today-session-container p {
        font-size: 0.9rem;
    }
    .join-session-btn {
        font-size: 0.9rem !important;
    }
}

@media (max-width: 1470px) {
    .schedule-content {
        padding: 10px 0 0 !important;
        max-width: 3000px !important;
        /* grid-template-columns: 1fr 1fr; */
    }
}

@media (max-width: 1205px) {
    .today-session-box {
        font-size: 1rem;
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .tomorrow-session-container .list-container {
        column-gap: 0;
    }
    .tomorrow-session-container .list-container .schedule-left {
        column-gap: 0.2rem;
    }
    .today-session-container .list-container {
        font-size: 0.9rem;
    }
    .today-session-container p {
        font-size: 0.9rem;
    }
    .join-session-btn {
        font-size: 0.9rem !important;
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
}

@media (max-height: 1100px) {
    .calendar-container,
    .tomorrow-session-container {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    /* .today-session-container {
    padding-right: 2rem;
    padding-left: 2rem;
  } */
    .tomorrow-session-container .list-container {
        align-items: center;
    }
    .tomorrow-session-container .list-container .schedule-left * {
        font-size: 0.8rem !important;
    }
    .today-session-container p,
    .tomorrow-session-container p {
        font-size: 0.8rem;
    }
}

/* @media (max-width: 1050px) {

  
} */

@media (max-width: 920px) {
    .schedule-content {
        gap: 1.5rem;
    }
    .schedule-content>* {
        border-radius: 2rem;
    }
    /* .today-session-container .list-container {
    row-gap: 1rem !important;
  } */
}

/* 
@media (max-width: 840px) {
  .schedule-content {
    grid-template-columns: 1.2fr 1fr;
  }

  .today-session-box {
    font-size: .8rem;
    padding: .3rem 1rem !important;
  }

  .schedule-content>* {
    border-radius: 1.5rem;
  }
}

@media (max-width: 715px) {
  .schedule-content {
    padding: 10px 20px 0;
  }
}

@media (max-width: 670px) {
  .calendar-container,
  .today-session-container,
  .tomorrow-session-container {
    padding-right: 1rem;
    padding-left: 1rem;
  }
} */

/* ===================== */

/* ===== MAX WIDTH ===== */

/* ===================== */

/* ==================================== */

/* ===== MAX WIDTH AND MAX HEIGHT ===== */

/* ==================================== */

@media (min-width: 1800px) and (max-height: 750px) {
    .schedule-content {
        column-gap: 10rem;
    }
    .tomorrow-session-container .today-session-box {
        font-size: 0.9rem;
    }
    .tomorrow-session-container .list-container,
    .tomorrow-session-container p {
        font-size: 0.9rem;
    }
}

/* ==================================== */

/* ===== MAX WIDTH AND MAX HEIGHT ===== */

/* ==================================== */

@media (max-width: 1850px) {
    .schedule-content {
        column-gap: 6rem;
        padding: 10px 6rem 0;
    }
}

@media (max-width: 1285px) {
    .schedule-content {
        column-gap: 3rem;
        padding: 10px 0 0 !important;
    }
}

@media (max-width: 1160px) {
    .schedule-content {
        padding: 10px 40px 0;
        grid-template-columns: 0.7fr minmax(100px, 400px) !important;
        /* max-width: 100vw !important; */
    }
}

@media (max-width: 1610px) and (max-height: 950px) {
    .schedule-content {
        grid-template-columns: 0.7fr minmax(100px, 450px);
    }
}

@media (max-width: 1550px) and (max-height: 740px) {
    /* .schedule-content {
    padding: 10px 10rem 0;
  } */
    .schedule-content {
        margin-top: 0rem;
        grid-template-columns: 0.8fr minmax(100px, 400px);
    }
    .today-session-container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .tomorrow-session-container .list-container *,
    .tomorrow-session-container p {
        font-size: 0.8rem;
    }
}

@media (max-width: 1300px) and (max-height: 650px) {
    .schedule-content {
        margin-top: 0rem;
        grid-template-columns: 0.6fr minmax(100px, 400px);
    }
    .tomorrow-session-container .today-session-box {
        font-size: 0.8rem !important;
        padding: 0.2rem 1rem !important;
        border-radius: 0.2rem !important;
    }
    .tomorrow-session-container .list-container {
        row-gap: 0.2rem !important;
    }
    .tomorrow-session-container p {
        font-size: 0.8rem !important;
    }
    .tomorrow-session-container {
        padding-bottom: 0.5rem;
    }
    .today-session-container .list-container {
        row-gap: 1rem !important;
    }
    .today-session-container .list-container * {
        font-size: 0.8rem;
    }
}

/* ========================= */

/* ===== PHONE VERSION ===== */

/* ========================= */

@media (max-width: 900px) {
    .schedule-content {
        justify-content: center;
        grid-template-columns: 500px !important;
        grid-template-rows: 1fr;
        height: 100%;
        max-height: 100% !important;
        padding-bottom: 2rem !important;
    }
    .today-session-container {
        grid-row: 1;
        min-height: 80vh;
    }
    .today-session-container .list-container {
        row-gap: 4rem !important;
    }
    .tomorrow-session-container .list-container {
        grid-template-columns: 1fr 1fr;
    }
    .schedule-content>* {
        padding: 3rem !important;
    }
    .today-session-container {
        min-height: 85vh;
    }
    .today-session-container .list-container {
        row-gap: 4rem !important;
    }
    .today-session-container .schedule-divider {
        margin-top: 3rem !important;
    }
}

@media (max-width: 620px) {
    .schedule-content {
        grid-template-columns: 350px !important;
        padding: 10px 20px 4rem !important;
    }
    .today-session-box {
        font-size: 0.8rem;
        padding: 0.3rem 1rem !important;
    }
}

@media (max-width: 400px) {
    .schedule-content {
        grid-template-columns: 260px !important;
        padding: 10px 20px 4rem !important;
    }
}

@media (max-width: 599px) {
    .schedule-content {
        margin-left: 0;
    }
}

@media (max-width: 465px) {
    .schedule-content {
        padding: 10px 20px 2rem;
    }
    .calendar-container,
    .today-session-container,
    .tomorrow-session-container {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .today-session-container .list-container {
        row-gap: 2rem !important;
    }
}

@media (max-width: 400px) {
    .today-session-container {
        min-height: 70vh;
    }
}

@media (max-width: 370px) {
    .schedule-content>* {
        padding: 3rem 1.5rem !important;
    }
}

/* ========================= */

/* ===== PHONE VERSION ===== */

/* ========================= */